import { navigateTo } from '@mfl/framework';
import { AccountBasicInfoResponse, userGateway } from '@msl/user-gateway-sdk';
import { userAnalyticsGateway } from '@msl/user-analytics-gateway-sdk';

import { auth4Sdks } from './auth';
import { doHeaderSetup } from './header';
import { doNavbarSetup } from './navigation';

export enum PlatformBootSequence {
  Normal,
  SkipPlugins,
  Stop,
}

import { getAccountBasicInfo, setAccountBasicInfo } from './session/account';

export async function platformBootstrap(): Promise<PlatformBootSequence> {
  console.info('platformBootstrap start');

  let accountData: AccountBasicInfoResponse | undefined;
  try {
    await userGateway.init(auth4Sdks());
    await userAnalyticsGateway.init(auth4Sdks());
    accountData = await userGateway.accountBasicInfo({});
    setAccountBasicInfo(accountData);
    handleOnBoardingRedirects();

    doHeaderSetup();
    doNavbarSetup();
  } catch (e) {
    console.error('get account basic info failed', e);
    throw e;
  }
  return PlatformBootSequence.Normal;
}

const ONBOARDING_ROUTE = '/v1/onboarding';

function handleOnBoardingRedirects(): void {
  const isInOnboarding: boolean = location.pathname === ONBOARDING_ROUTE;
  const { signupFlowCompleted } = getAccountBasicInfo();

  if (!signupFlowCompleted && !isInOnboarding) {
    navigateTo(ONBOARDING_ROUTE);
  }
}
