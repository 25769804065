export default {
  subscriptionTrialEndedText:
    'Your Team trial has ended, and signatures deactivated',
  subscriptionExpiredText:
    'Your subscription has ended, and signatures deactivated',
  headerTrialActiveTextNoDiscount: 'Your free trial ends in {remainDays} days',
  headerTrialActiveText:
    'Your free trial ends in {remainDays} days. Upgrade now and get {discount}% early bird discount',
  subscriptionGracePeriodText: 'Your subscription has ended',
  cancelTeamAccountBtn: 'Or, cancel team account',
  upgradeNowBtn: 'Upgrade now',
  scheduleDemoBtn: 'Schedule a demo',
  tryForTeamsBtn: 'Try WiseStamp for Teams',
  subscriptionRenewBtn: 'Renew now',
  userMenuProfile: 'Profile',
  userMenuContactUs: 'Contact us',
  userMenuHelpCenter: 'Help center',
  userMenuSystemStatus: 'System status',
  userMenuSignIn: 'Sign in',
  userMenuLogout: 'Log out',
  userMenuAccountUsers: 'Account users',
  userMenuBilling: 'Plan & Billing',
  headerWarningSignEmail:
    "Caution: you're logged in as: {accountOwnerEmail}. Make sure to log out once you're done.",
};
