export default {
  addOrganizationHeader: 'New organization',
  addOrganizationButton: 'New organization',
  getListError: 'get organization list failed',
  createOrganizationError: 'create organization failed',
  nameYourOrganization: 'What would you like to name your organization?',
  yourEmailPlatform: 'What is your email platform?',
  emailPlatformOptionMicrosoft: 'Microsoft',
  emailPlatformOptionGoogle: 'Google Workspace',
  emailPlatformOptionOther: 'Other',
  createOrganizationButton: 'Create organization',
  createOrganizationCancelButton: 'Cancel',
  nameYourOrganizationPlaceholder:
    'Try the name of your company or organization',
  organizationNameRequired: 'Organization name is required',
  organizationNameIsInUse: 'This name is already in use',
  addOrganizationDisabledTooltip: 'Complete setup to add another organization',
  upgradeBadge: 'Upgrade',
  personalWorkspace: 'Personal',
};
