<script setup lang="ts">
import { ref } from 'vue';
import { OrgEmailPlatform } from '@msl/user-gateway-sdk';
import translations from './organization-selector.strings';

type RadioButtonOption = {
  value: OrgEmailPlatform;
  label: string;
  className?: string;
};

const emailPlatformOptions = ref<RadioButtonOption[]>([
  {
    value: OrgEmailPlatform.MICROSOFT,
    label: translations.emailPlatformOptionMicrosoft,
    className: 'microsoft',
  },
  {
    value: OrgEmailPlatform.GOOGLE,
    label: translations.emailPlatformOptionGoogle,
    className: 'google',
  },
  {
    value: OrgEmailPlatform.OTHER,
    label: translations.emailPlatformOptionOther,
  },
]);

type Props = {
  disabled?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
});

const model = defineModel<OrgEmailPlatform>();

function onClick(option: RadioButtonOption) {
  if (!props.disabled) {
    model.value = option.value;
  }
}
</script>
<template>
  <div aid="EMAIL_PLATFORM_SELECTOR" class="chips-group">
    <div
      v-for="option in emailPlatformOptions"
      :key="`${option.value}`"
      :class="[
        'text-sm',
        'font-normal',
        'chip',
        { selected: model == option.value },
        { disabled: disabled },
      ]"
      :aid="`EMAIL_PLATFORM_SELECTOR_${OrgEmailPlatform[option.value]}`"
      @click="onClick(option)"
    >
      <span :class="option.className">{{ option.label }}</span>
    </div>
  </div>
</template>
<style>
.chips-group {
  display: flex;
  align-items: center;
  gap: 14px;
  flex-wrap: wrap;
}

.chip {
  padding: 4px 24px;
  height: 48px;
  border-radius: 50px;
  border: 1px solid #e0e0e0;
  text-align: center;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chip:hover {
  cursor: pointer;
}

.selected {
  border: 1px solid #1e90fa;
}
</style>
