import {
  headerAnnouncementComponents,
  headerRightComponents,
} from '@mfe/core-header';

import HeaderUserMenu from './header-user-menu.vue';
import HeaderActionButtons from './header-action-buttons.vue';
import ImpersonationAnnouncementBar from './impersonation-announcement-bar.vue';
import HeaderAnnouncementBar from './header-announcement-bar.vue';
import { getAccountBasicInfo } from '../session';

export function doHeaderSetup() {
  const accountData = getAccountBasicInfo();
  // PROMOTIONS
  headerRightComponents.register({
    key: 'PLATFORM_ACTION_BUTTONS',
    component: HeaderActionButtons,
    props: accountData,
  });

  // USER MENU
  headerRightComponents.register({
    key: 'PLATFORM_USER_MENU',
    component: HeaderUserMenu,
  });

  // IMPERSONATION
  const isImpersonated = sessionStorage.getItem('isImpersonated');

  if (isImpersonated) {
    headerAnnouncementComponents.register({
      key: 'PLATFORM_IMPERSONATION',
      component: ImpersonationAnnouncementBar,
      props: accountData,
    });
  }

  // ANNOUNCEMENT
  headerAnnouncementComponents.register({
    key: 'PLATFORM_PROMOTION_ANNOUNCEMENT',
    component: HeaderAnnouncementBar,
    props: accountData,
  });
}
