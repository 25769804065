import { Priority, navigateTo } from '@mfl/framework';
import { prompt, PromptResult } from '@mfl/common-components';
import { NavigationItem, navigationItems } from '@mfe/core-navigation';
import { AccountBasicInfoResponse, AccountStages } from '@msl/user-gateway-sdk';

import { currentUser, getAccountBasicInfo } from '../session';
import { EntitelmentTypes, PermissionTypes, WEBAPP_BASE_URL } from '../consts';

import strings from './global-navigation.strings';

const PERSONAL_SIGNATURES_MODE = 'personal-signatures';

export function registerGlobalNavigationItems() {
  const accountData = getAccountBasicInfo();
  addMySignaturesNavigationItem();
  addEmployeesNavigationItem();
  addSignaturesNavigationItem();
  addConnectorsNavigationItem();
  addCompanyFieldsNavigationItem(accountData);
  addEmployeeFieldsNavigationItem(accountData);
  addSignatureRulesNavigationItem();
  addSignatureSettingsNavigationItem();
}
const webAppNavigationLinkHandler = async (
  item: NavigationItem
): Promise<boolean> => {
  await navigateTo(WEBAPP_BASE_URL + item.route, { reload: true });
  return false;
};

export const openUpgradeModal = async (): Promise<boolean> => {
  const confirmResult = await prompt({
    aid: 'UPGRADE_PROMPT_FROM_NAV_BAR',
    header: strings.upgradeModalTitle,
    question: strings.upgradeModalStatement,
    primaryButtonText: 'Upgrade',
    secondaryButtonText: 'Cancel',
  });
  if (confirmResult === PromptResult.Primary) {
    navigateTo(`${WEBAPP_BASE_URL}/?upgradeNow`, {
      reload: true,
      newTab: true,
    });
  }
  return false;
};

const createProNavigationItem = (
  key: string,
  text: string,
  fontAwesomeIcon: string
): NavigationItem => {
  return {
    key,
    text,
    fontAwesomeIcon,
    route: '',
    handler: openUpgradeModal,
    ornaments: [{ type: 'pro' }],
  };
};

/**
 * These entries point to the old frontend\
 * When a module is migrated to ws1 its entry should be removed from to the modules' plugin
 */

function addMySignaturesNavigationItem() {
  const MY_SIGNATURES_KEY = 'MY_SIGNATURES';
  if (
    !currentUser.isEntitledTo(EntitelmentTypes.commonTeamsFeatures) &&
    currentUser.isEntitledTo(EntitelmentTypes.commonSoloFeatures)
  ) {
    navigationItems.register(
      {
        key: MY_SIGNATURES_KEY,
        text: strings.mySignatures,
        fontAwesomeIcon: 'file-signature',
        route: '/my-signatures',
        handler: webAppNavigationLinkHandler,
      },
      { priority: Priority.Medium - 10 }
    );
  }
}

function addEmployeesNavigationItem() {
  const EMPLOYEES_KEY = 'EMPLOYEES';
  const EMPLOYEES_ICON = 'users';
  if (
    currentUser.domainId !== PERSONAL_SIGNATURES_MODE &&
    currentUser.isAllowedTo(PermissionTypes.manageEmployee) &&
    currentUser.isAllowedTo(PermissionTypes.viewEmployee)
  ) {
    if (currentUser.isEntitledTo(EntitelmentTypes.commonTeamsFeatures)) {
      navigationItems.register(
        {
          key: EMPLOYEES_KEY,
          text: strings.employees,
          fontAwesomeIcon: EMPLOYEES_ICON,
          route: '/employees',
          handler: webAppNavigationLinkHandler,
        },
        { priority: Priority.Medium - 20 }
      );
    } else {
      navigationItems.register(
        createProNavigationItem(
          EMPLOYEES_KEY,
          strings.employees,
          EMPLOYEES_ICON
        ),
        { priority: Priority.Medium - 20 }
      );
    }
  }
}

function addSignaturesNavigationItem() {
  const SIGNATURES_KEY = 'SIGNATURES';
  if (
    currentUser.isEntitledTo(EntitelmentTypes.commonTeamsFeatures) &&
    currentUser.isAllowedTo(PermissionTypes.manageSignature) &&
    currentUser.isAllowedTo(PermissionTypes.editSignature)
  ) {
    navigationItems.register(
      {
        key: SIGNATURES_KEY,
        text: strings.signatures,
        fontAwesomeIcon: 'file-signature',
        route: '/signatures',
        handler: webAppNavigationLinkHandler,
      },
      { priority: Priority.Medium - 30 }
    );
  }
}

function addConnectorsNavigationItem() {
  const CONNECTORS_KEY = 'CONNECTORS';
  if (
    currentUser.isEntitledTo(EntitelmentTypes.commonTeamsFeatures) &&
    currentUser.domainId !== PERSONAL_SIGNATURES_MODE
  ) {
    navigationItems.register(
      {
        key: CONNECTORS_KEY,
        text: strings.connectors,
        fontAwesomeIcon: 'puzzle-piece-simple',
        route: '/connectors',
        handler: webAppNavigationLinkHandler,
      },
      { priority: Priority.Medium - 40 }
    );
  }
}

function addCompanyFieldsNavigationItem(accountData: AccountBasicInfoResponse) {
  const COMPANY_FIELDS_KEY = 'COMPANY_FIELDS';
  if (
    currentUser.isEntitledTo(EntitelmentTypes.commonTeamsFeatures) &&
    currentUser.domainId !== PERSONAL_SIGNATURES_MODE &&
    !(accountData.stage == AccountStages.trialExpired)
  ) {
    navigationItems.register(
      {
        key: COMPANY_FIELDS_KEY,
        text: strings.companyDetails,
        fontAwesomeIcon: 'sitemap',
        route: '',
        handler: async (_item: NavigationItem): Promise<boolean> => {
          navigateTo(WEBAPP_BASE_URL + '/employees?openModal=company-fields', {
            newTab: true,
            reload: true,
          });
          return false;
        },
      },
      { priority: Priority.Medium - 70 }
    );
  }
}

function addEmployeeFieldsNavigationItem(
  accountData: AccountBasicInfoResponse
) {
  const EMPLOYEE_FIELDS_KEY = 'EMPLOYEE_FIELDS';
  if (
    currentUser.isEntitledTo(EntitelmentTypes.commonTeamsFeatures) &&
    currentUser.domainId !== PERSONAL_SIGNATURES_MODE &&
    !(accountData.stage == AccountStages.trialExpired)
  ) {
    navigationItems.register(
      {
        key: EMPLOYEE_FIELDS_KEY,
        text: strings.employeeFields,
        fontAwesomeIcon: 'user-pen',
        route: '',
        handler: async (_item: NavigationItem): Promise<boolean> => {
          navigateTo(WEBAPP_BASE_URL + '/employees?openModal=employee-fields', {
            reload: true,
            newTab: true,
          });
          return false;
        },
      },
      { priority: Priority.Medium - 80 }
    );
  }
}

function addSignatureRulesNavigationItem() {
  const SIGNATURE_RULES_KEY = 'SIGNATURE_RULES';
  const SIGNATURE_RULES_ICON = 'list-check';
  if (
    currentUser.isEntitledTo(EntitelmentTypes.commonTeamsFeatures) &&
    currentUser.domainId !== PERSONAL_SIGNATURES_MODE &&
    currentUser.isAllowedTo(PermissionTypes.editSignatureRules)
  ) {
    if (
      currentUser.isEntitledTo(EntitelmentTypes.serverSideRenderingRulesView)
    ) {
      navigationItems.register(
        {
          key: SIGNATURE_RULES_KEY,
          text: strings.signatureRules,
          fontAwesomeIcon: SIGNATURE_RULES_ICON,
          route: '/signature-rules',
          handler: webAppNavigationLinkHandler,
        },
        { priority: Priority.Medium - 90 }
      );
    } else {
      navigationItems.register(
        createProNavigationItem(
          SIGNATURE_RULES_KEY,
          strings.signatureRules,
          SIGNATURE_RULES_ICON
        ),
        { priority: Priority.Medium - 90 }
      );
    }
  }
}

function addSignatureSettingsNavigationItem() {
  const SINATURE_SETTINGS_KEY = 'SINATURE_SETTINGS';
  if (
    !currentUser.isEntitledTo(EntitelmentTypes.commonTeamsFeatures) &&
    currentUser.isEntitledTo(EntitelmentTypes.commonSoloFeatures)
  ) {
    navigationItems.register(
      {
        key: SINATURE_SETTINGS_KEY,
        text: strings.signatureSettings,
        fontAwesomeIcon: 'gear',
        route: '',
        handler: async (_item: NavigationItem): Promise<boolean> => {
          // TODO: openSidePanel: "signature-settings;
          return false;
        },
      },
      { priority: Priority.Medium - 100 }
    );
  }
}
