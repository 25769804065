<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';

import { Organization, organizationApi } from '@msl/user-gateway-sdk';
import { navigateTo } from '@mfl/framework';
import {
  toast,
  ToastStatus,
  WsSelect,
  WsSelectOption,
  WsSelectPanel,
  WsSelectOptions,
  WsSelectTrigger,
  WsTooltip,
  WsBadge,
} from '@mfl/common-components';
import { navigationBarMode, NavigationBarMode } from '@mfe/core-navigation';

import { auth4Sdks } from '../../auth';
import { currentUser, getAccountBasicInfo } from '../../session';
import { WEBAPP_BASE_URL } from '../../consts';
import { openAddOrganizationDialog } from './add-organization-dialog';
import translations from './organization-selector.strings';
import { openUpgradeModal } from '../global-navigation-items';
import { setupCompleted } from '../../session/account';

// React to navigation bar mode change (example)
const isMinimized = computed(
  () => navigationBarMode.value === NavigationBarMode.MinimalView
);

const isLoading = ref(true);
const organizationList = ref<Organization[]>([]);
const { hasPersonalSignatures } = getAccountBasicInfo();

const selectedOrganization = ref<Organization>();

const upgradeRequired = computed(
  () => !currentUser.isEntitledTo('domains.create')
);

//button is shown only when user has permission
const addOrganizationPermitted = computed(() =>
  currentUser.isAllowedTo('account.manage')
);

//button is disabled when setup is not completed
const addOrganizationDisabled = computed(() => !setupCompleted.value);

onBeforeMount(async () => {
  try {
    await organizationApi.init(auth4Sdks());
    const res = await organizationApi.getOrganizationsList({});
    organizationList.value = res.result || [];

    if (hasPersonalSignatures)
      organizationList.value.push({
        id: 'personal-signatures',
        key: '',
        domain: '',
        organizationDisplayName: translations.personalWorkspace,
      });

    selectedOrganization.value = organizationList.value.find(
      (v) => v.id == currentUser.domainId
    );

    isLoading.value = false;
  } catch (e) {
    console.error('get organization list failed', e);
    toast({
      status: ToastStatus.Error,
      message: translations.getListError,
      aid: 'ORG_SELECTOR_ERROR_TOAST',
    });
  }
});

async function selectOrganization(val: Organization | Organization[] | null) {
  const org = val as Required<Organization>;
  await organizationApi.setDefaultOrganization({ organizationId: org.id });

  sessionStorage.setItem('domainId', org.id);
  sessionStorage.setItem('domainKey', org.key);

  if (org.id === 'personal-signatures')
    navigateTo(`${WEBAPP_BASE_URL}/my-signatures`, { reload: true });
  else navigateTo(window.location.pathname, { reload: true });
}

function handleAddOrganization() {
  if (upgradeRequired.value) {
    return openUpgradeModal();
  }

  if (addOrganizationDisabled.value) {
    return;
  }

  openAddOrganizationDialog({ organizationList: organizationList.value });
}
</script>

<template>
  <div class="platform-domains" aid="ORGANIZATION_SELECTOR">
    <WsSelect
      v-model="selectedOrganization"
      :loading="isLoading"
      variant="text"
      v-bind="{
        optionLabel: (val: Organization) => val?.organizationDisplayName || '',
        optionKey: (val: Organization) => val?.id || '',
        aid: 'ORGANIZATION_SELECT',
      }"
      advanced
      @update:model-value="selectOrganization"
    >
      <Transition name="fade" mode="out-in">
        <div v-if="isMinimized">
          <WsSelectTrigger>
            <i class="fa-regular fa-house-user"></i>
          </WsSelectTrigger>
        </div>
        <div v-else>
          <WsSelectTrigger class="org-selector-trigger" />
        </div>
      </Transition>
      <WsSelectPanel>
        <WsSelectOptions>
          <WsSelectOption
            v-for="org in organizationList"
            :key="org.id"
            :value="org"
          >
            {{ org.organizationDisplayName }}
          </WsSelectOption>
        </WsSelectOptions>
        <button
          v-if="addOrganizationPermitted"
          aid="ADD_ORGANIZATION_BTN"
          role="button"
          tabindex="0"
          :disabled="addOrganizationDisabled"
          :class="['add-organization-button', 'text-primary']"
          @click="handleAddOrganization"
        >
          <i class="fa-regular fa-plus-circle" />
          <span>
            {{ translations.addOrganizationButton }}
            <WsTooltip
              v-if="addOrganizationDisabled"
              aid="ADD_ORGANIZATION_BUTTON_TOOLTIP"
            >
              {{ translations.addOrganizationDisabledTooltip }}
            </WsTooltip>
          </span>
          <WsBadge
            v-if="upgradeRequired"
            :label="translations.upgradeBadge"
            size="md"
            icon="fa-regular fa-circle-arrow-up"
            aid="ADD_ORGANIZATION_UPGRADE"
            class="text-uppercase shrink-0"
          />
        </button>
      </WsSelectPanel>
    </WsSelect>
  </div>
</template>

<style scoped>
.platform-domains {
  text-align: center;
  height: 45px;
  border-bottom: 1px solid rgb(var(--color-gray-100));
  display: flex;
  align-items: center;
  justify-content: center;
  /* avoid getting bloated of shrunk */
  flex: 0 0 auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 200ms ease-out;
}

.fade-enter-from {
  opacity: 0;
}

.fade-leave-to {
  opacity: 0;
}

.org-selector-trigger {
  width: 200px;
  min-height: 40px;
}

.add-organization-button {
  border-top: 1px solid rgb(var(--color-gray-100));
  padding: 10px 20px;
  width: max-content;

  i {
    margin-right: 8px;
  }

  span {
    margin-right: 20px;
  }
}

/* Need to get rid of this once ws-select in text varian is fixed - it now collapses on itself */
:deep(span[aid='ORGANIZATION_SELECT_SELECTED']) {
  min-width: 150px;
  text-align: left;
}
</style>
