<script setup lang="ts">
import { navigateTo } from '@mfl/framework';
import { currentUser } from '../session';
import {
  WsMenu,
  WsMenuItem,
  WsMenuSeparator,
  WsIconButton,
} from '@mfl/common-components';
import { PermissionTypes, EntitelmentTypes, WEBAPP_BASE_URL } from '../consts';
import translations from './header.strings';

export interface UserMenuItem {
  aid: string;
  title: string;
  route?: string;
  icon?: string;
  separator?: boolean;
  available?: boolean;
  newTab?: boolean;
  reload?: boolean;
  endIcon?: string;
}

defineProps({
  userInitials: {
    type: String,
    default: '',
  },
});

const userMenu: UserMenuItem[] = [
  {
    aid: 'user-menu-profile',
    title: translations.userMenuProfile,
    icon: 'fa-light fa-user',
    route: `${WEBAPP_BASE_URL}/profile`,
    reload: true,
    available: !!currentUser.accountId,
  },
  {
    aid: 'user-menu-billing',
    title: translations.userMenuBilling,
    icon: 'fa-light fa-credit-card',
    route: `${WEBAPP_BASE_URL}/billing`,
    reload: true,
    available: currentUser.isAllowedTo(PermissionTypes.accountBilling),
    separator: !(
      currentUser.isEntitledTo(EntitelmentTypes.commonTeamsFeatures) &&
      currentUser.isAllowedTo(PermissionTypes.accountManage)
    ),
  },
  {
    aid: 'user-menu-account-users',
    title: translations.userMenuAccountUsers,
    icon: 'fa-light fa-user-shield',
    separator: true,
    route: '/v1/account-settings/users',
    available:
      currentUser.isEntitledTo(EntitelmentTypes.commonTeamsFeatures) &&
      currentUser.isAllowedTo(PermissionTypes.accountManage),
  },
  {
    aid: 'user-menu-help-center',
    title: translations.userMenuHelpCenter,
    route: 'https://support.wisestamp.com/hc/en-us',
    newTab: true,
    icon: 'fa-light fa-circle-question',
    available: true,
    endIcon: 'fa-light fa-arrow-up-right-from-square',
  },
  {
    aid: 'user-menu-system-status',
    title: translations.userMenuSystemStatus,
    route: 'https://status.wisestamp.com/',
    newTab: true,
    icon: 'fa-light fa-wave-pulse',
    available: true,
    endIcon: 'fa-light fa-arrow-up-right-from-square',
  },
  {
    aid: 'user-menu-contact-us',
    title: translations.userMenuContactUs,
    route: 'https://support.wisestamp.com/hc/en-us/requests/new',
    newTab: true,
    icon: 'fa-light fa-comment',
    separator: true,
    available: true,
    endIcon: 'fa-light fa-arrow-up-right-from-square',
  },
  {
    aid: 'user-menu-logout',
    title: translations.userMenuLogout,
    icon: 'fa-light fa-arrow-right-from-bracket',
    route: `${WEBAPP_BASE_URL}/logout`,
    reload: true,
    available: !!currentUser.accountId,
  },
  {
    aid: 'login',
    title: translations.userMenuSignIn,
    icon: 'fa-light fa-arrow-left-from-bracket',
    available: !currentUser.accountId,
  },
].filter((el) => el.available);
</script>

<template>
  <ws-icon-button color="primary-300" size="md" aid="USER_MENU_BUTTON">
    <span v-if="userInitials">{{ userInitials }}</span>
    <span v-else class="fa-solid fa-user" style="font-size: 1rem" />

    <ws-menu aid="USER_MENU" min-width="240px">
      <template v-for="menuItem in userMenu" :key="menuItem.aid">
        <ws-menu-item
          clickable
          :icon="menuItem.icon"
          :aid="menuItem.aid"
          :end-icon="menuItem.endIcon"
          @click="
            navigateTo(menuItem.route, {
              newTab: menuItem.newTab,
              reload: menuItem.reload,
            })
          "
        >
          {{ menuItem.title }}
        </ws-menu-item>
        <ws-menu-separator v-if="menuItem.separator" />
      </template>
    </ws-menu>
  </ws-icon-button>
</template>
