export default {
  mySignatures: 'My Signatures',
  employees: 'Employees',
  signatures: 'Signatures',
  connectors: 'Connectors',
  campaigns: 'Campaigns',
  analytics: 'Analytics',
  companyDetails: 'Company details',
  employeeFields: 'Employee fields',
  signatureRules: 'Signature rules',
  signatureSettings: 'Signature settings',
  settings: 'Settings',
  businessListings: 'Business Listings',
  businessWebpage: 'Business Webpage',
  businessCards: 'Business Cards',
  signFilesOnline: 'Sign files Online',
  upgradeModalTitle: 'Upgrade account',
  upgradeModalStatement: 'Upgrade your account to access this feature',
};
