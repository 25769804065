<script setup lang="ts">
import { ref } from 'vue';
import {
  CreateOrganizationRequest,
  OrgEmailPlatform,
  organizationApi,
} from '@msl/user-gateway-sdk';
import {
  toast,
  ToastStatus,
  WsButton,
  WsInput,
  IDialog,
  WsDialogSection,
  WsDialogActions,
} from '@mfl/common-components';

import { AddOrganizationInput } from './add-organization-dialog';
import EmailPlatformSelector from './email-platform-selector.vue';
import translations from './organization-selector.strings';
import { navigateTo } from '@mfl/framework';

const { dialog } = defineProps<{
  dialog: IDialog<AddOrganizationInput, void>;
}>();

const inProgress = ref<boolean>(false);

const addOrganizationData = ref<CreateOrganizationRequest>({
  emailPlatform: OrgEmailPlatform.OTHER,
  organizationName: '',
});

function organizationNameValidation(val?: string) {
  val = val?.trim().toLowerCase();
  if (!val) return translations.organizationNameRequired;
  if (
    dialog.input.organizationList.some(
      (org) =>
        org.organizationDisplayName &&
        val == org.organizationDisplayName.trim().toLowerCase()
    )
  )
    return translations.organizationNameIsInUse;

  return true;
}

async function createOrganization() {
  if (!addOrganizationData.value.organizationName) return;
  try {
    inProgress.value = true;
    const orgCreated = await organizationApi.createOrganization(
      addOrganizationData.value
    );
    if (orgCreated.organizationId && orgCreated.organizationKey) {
      sessionStorage.setItem('domainId', orgCreated.organizationId);
      sessionStorage.setItem('domainKey', orgCreated.organizationKey);
      navigateTo(window.location.pathname, { reload: true });
    }
  } catch (e) {
    console.error('create organization error', e);
    toast({
      status: ToastStatus.Error,
      message: translations.createOrganizationError,
      aid: 'CREATE_ORGANIZAION_ERROR_TOAST',
    });
  } finally {
    inProgress.value = false;
  }
}
</script>
<template>
  <WsDialogSection class="add-org-dialog">
    <div class="text-sm font-normal organization-name">
      {{ translations.nameYourOrganization }}
    </div>
    <WsInput
      v-model.trim="addOrganizationData.organizationName"
      aid="ONBOARDING_USER_NAME"
      size="md"
      :disabled="inProgress"
      :placeholder="translations.nameYourOrganizationPlaceholder"
      :rules="[organizationNameValidation]"
    ></WsInput>
    <div class="text-sm font-normal email-platform">
      {{ translations.yourEmailPlatform }}
    </div>
    <EmailPlatformSelector
      v-model="addOrganizationData.emailPlatform"
      :disabled="inProgress"
    ></EmailPlatformSelector>
  </WsDialogSection>
  <WsDialogActions>
    <WsButton
      color="gray-500"
      variant="outlined"
      :label="translations.createOrganizationCancelButton"
      :disabled="inProgress"
      size="md"
      aid="ADD_ORGANIZATION_CANCEL_BUTTON"
      @click="dialog.close()"
    >
    </WsButton>

    <WsButton
      color="primary"
      :label="translations.createOrganizationButton"
      :loading="inProgress"
      :disabled="!addOrganizationData.organizationName"
      size="md"
      aid="ADD_ORGANIZATION_BUTTON"
      @click="createOrganization"
    >
    </WsButton>
  </WsDialogActions>
</template>
<style>
.email-platform {
  padding-top: 4px;
  padding-bottom: 6px;
}
.microsoft {
  background-image: url('./assets/microsoft.svg');
  background-repeat: no-repeat;
  background-size: 20px;
  padding-left: 30px;
}

.google {
  background-image: url('./assets/google.svg');
  background-repeat: no-repeat;
  background-size: 20px;
  padding-left: 30px;
}

.organization-name {
  padding-top: 10px;
  padding-bottom: 6px;
}

.add-org-dialog {
  width: 560px;
}
</style>
