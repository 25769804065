/**
 * Make sure this file does not become a bin for all constants in the platform
 * Most constants should be defined in the relevant module
 * The backend SDK or the frontend MFE in most cases
 */

import { isLocalDev } from '@mfl/framework';

/**
 * Globally used entitlements\
 * Do not add module-specific ones here
 */
export enum EntitelmentTypes {
  commonSoloFeatures = 'common-solo-features',
  commonTeamsFeatures = 'common-teams-features',
  // TODO: remove after implement mfe
  analytics = 'analytics',
  campaigns = 'campaigns',
  serverSideRenderingRulesView = 'server_side_rendering_rules.view',
}

/**
 * Globally used permissions\
 * Do not add module-specific ones here
 */
export enum PermissionTypes {
  accountBilling = 'account.billing',
  accountManage = 'account.manage',
  // TODO: remove after implement mfe
  manageEmployee = 'employee.manage',
  viewEmployee = 'employee.view',
  manageSignature = 'signature.manage',
  editSignature = 'signature.edit',
  manageCampaigns = 'campaigns.manage',
  manageAnaytics = 'analytics.manage',
  editSignatureRules = 'signature.rules.edit',
  manageDomain = 'domain.manage',
}

/*
when we redirect to old webapp and we need to trigger some dialog there we pass one of those params
*/
export enum webAppPopupFlags {
  upgradeNow = 'upgradeNow',
  cancelTeamAccount = 'cancelTeamAccount',
  tryForTeams = 'tryForTeams',
}

/** A convenience constant - helps with switching to webapp when running locally */
export const WEBAPP_BASE_URL = isLocalDev ? 'http://localhost:9081' : '';
