import { Organization } from '@msl/user-gateway-sdk';
import { openDialog } from '@mfl/common-components';
import AddOrganizationDialog from './add-organization-dialog.vue';
import translations from './organization-selector.strings';

export type AddOrganizationInput = {
  organizationList: Organization[];
};

export const openAddOrganizationDialog = async (
  input: AddOrganizationInput
): Promise<undefined> => {
  return await openDialog<AddOrganizationInput, undefined>({
    aid: 'CREATE_ORGANIZATION_DIALOG',
    headerText: translations.addOrganizationHeader,
    component: AddOrganizationDialog,
    input,
  });
};
