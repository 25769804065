import { ref } from 'vue';
import { AccountBasicInfoResponse } from '@msl/user-gateway-sdk';

let accountBasicInfo: AccountBasicInfoResponse | undefined;

export function getAccountBasicInfo(): AccountBasicInfoResponse {
  if (!accountBasicInfo) {
    throw new Error('Accont basic info is not available yet');
  }

  return accountBasicInfo;
}

export function setAccountBasicInfo(data: AccountBasicInfoResponse): void {
  accountBasicInfo = data;
  setupCompleted.value = accountBasicInfo.setupCompleted || false;
}

export const setupCompleted = ref<boolean>(false);
/**
 * signals the organization selector that setup was completed
 *
 * the selector then enables create organization button
 */
export function onSetupCompleted() {
  setupCompleted.value = true;
}
