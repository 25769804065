import { Priority } from '@mfl/framework';
import { navigationComponents } from '@mfe/core-navigation';

import { registerGlobalNavigationItems } from './global-navigation-items';
import OrganizationSelector from './organization-selector/organization-selector.vue';
import { getAccountBasicInfo } from '../session';

/**
 * Setup navigation items and custom components\
 * These items are temporary for the migration period
 */
export function doNavbarSetup() {
  navigationComponents.register(
    {
      key: 'ORGANIZATION_SELECTOR',
      component: OrganizationSelector,
      props: getAccountBasicInfo(),
    },
    {
      priority: Priority.High,
    }
  );

  registerGlobalNavigationItems();
}
